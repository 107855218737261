import { GImage } from '../../g-image';
import { whyGoGlobalData } from './data';

type props = {
  hasAlternativeHeaderStyle?: boolean;
};

function WhyGoGlobal({ hasAlternativeHeaderStyle }: props): JSX.Element {
  return (
    <div className="max-w-6.75xl xl:mx-auto mx-5 md:px-8 xl:px-10 text-dark-900">
      <h2
        className={
          hasAlternativeHeaderStyle
            ? 'text-dark-700 text-4xl mb-10 font-semibold md:text-5xl lg:text-5.5xl lg:text-center'
            : 'text-3xl leading-8 font-bold mb-5'
        }
      >
        {whyGoGlobalData.title}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8">
        {whyGoGlobalData.items.map((item, index) => (
          <div
            key={index}
            className="lg:flex lg:flex-col lg:items-center lg:text-center md:max-w-21.562rem"
          >
            <div>
              <GImage
                alt={item.title}
                path={item.icon}
                height="3.25rem"
                width="4rem"
                classes="!object-contain"
              />
            </div>
            <h2 className="text-xl font-bold text-teal-800 my-2.5 ">
              {item.title}
            </h2>
            <p className="text-base leading-5 text-dark-700">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export { WhyGoGlobal };
