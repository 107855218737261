import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { AboveTheFold } from '@/Components/division/above-the-fold';
import { WhyGoGlobal } from '@/Components/division/why-go-global';
import { Gallery } from '@/Components/gallery';
import { Trips } from '@/Components/trips';
import BlogDivision from '@/Components/division/blog';

import { initializeApollo } from '@/Lib/graphql/apollo-client';
import { DIVISIONS_QUERY } from '@/Lib/graphql/queries/divisions.query';
import { Division } from '@/Lib/types/division';
import { BannerPathFactory } from '@/Lib/helpers/banner-path-factory';
import { REGIONS_QUERY } from '@/Lib/graphql/queries/region.query';
import { TripDataType } from '@/Lib/types/trip';
import { TRIPS } from '@/Lib/graphql/queries/trip.query';
import { GALLERY_QUERY } from '@/Lib/graphql/queries/gallery.query';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useEffect, useState } from 'react';
import { TMeta } from '@/Lib/types/merketing';
import { round } from '@/Lib/helpers/round-number';
import { TFilterType } from '@/Pages/search';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ActivityTagData } from '@/Lib/types/activity-tags';
import { ACTIVITY_TAGS_BY_DIVISION_ID } from '@/Lib/graphql/queries/activity-tags.query';
import {
  getActivityTags,
  getGroupedRegionsAndDestinations,
  TSimpleFilter,
} from '@/Components/division/trip-filters';
import { TDropDownGroup } from '@/Components/drop-down/types';
import { BlogData } from '@/Lib/types/blog';
import { GET_BLOGPOST_QUERY } from '@/Lib/graphql/queries/blog.query';
import { navigationDataFn } from '@/Lib/function/navigation';
import { galleryData } from '@/Lib/types/gallery';
import { getMeta } from '@/Components/meta/meta.query';
import { generateUrl } from '@/Components/g-image';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';
import { getHeaderData } from '@/Lib/graphql/helpers/header-data';

type props = {
  internalName: any;
  division: Division;
  meta: TMeta;
  tripsData: TripDataType;
  dataBlog: BlogData;
  galleryData: galleryData;
  activityTagsData: ActivityTagData;
};

export const getDivisionNameForTripQuery = (givenName: string) => {
  switch (givenName) {
    case 'working-holiday':
      return 'Working Holiday';
    case 'volunteer-abroad':
      return 'Volunteer';
    case 'au-pair-abroad':
      return 'Au Pair';
    case 'teach-abroad':
      return 'Teach';
    case 'internship-abroad':
      return 'Internship';
    case 'summer-camp':
      return 'Summer Camp';
    case 'tutor-abroad':
      return 'Tutor';
    case 'study-abroad':
      return 'Study';
    case 'remote':
      return 'Remote';
    case 'tour':
      return 'Tour';
  }
};

const Division: NextPage<props> = ({
  division,
  tripsData,
  dataBlog,
  galleryData,
  activityTagsData,
}) => {
  const router = useRouter();
  const [allActivityTags, setAllActivityTags] = useState<TSimpleFilter[]>([]);
  const [allRegions, setAllRegions] = useState<TDropDownGroup[]>([]);
  const [activityTags, setActivityTags] = useState<TFilterType[]>([]);
  const [destinations, setDestinations] = useState<TFilterType[]>([]);
  const cover = BannerPathFactory.fromDivision(division.type);
  const [reviewsSummary, setReviewsSummary] = useState<{
    count: number;
    rating: number;
  }>({
    count: 0,
    rating: 0,
  });

  useEffect(() => {
    setAllActivityTags(getActivityTags(activityTagsData));
  }, [activityTagsData]);

  useEffect(() => {
    if (tripsData) {
      setAllRegions(getGroupedRegionsAndDestinations(tripsData.trips.items));
    }
    // eslint-disable-next-line
  }, [tripsData?.trips.items]);

  const { location } = useLocation();

  useEffect(() => {
    tripsData &&
      RudderAnalytics.productListViewed({
        products: tripsData.trips.items.map((t) => ({ product_id: t.id })),
        division: division.type,
        country: location.country.name,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tripsData) {
      const ratingsSum = tripsData.trips.items.reduce(
        (previousValue, currentValue) => {
          if (currentValue.reviews.rating) {
            return previousValue + Number(currentValue.reviews.rating);
          }
          return previousValue;
        },
        0
      );
      const tripsCount = tripsData.trips.items.filter(
        (t) => t.reviews.count
      ).length;
      const count = tripsData.trips.items.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.reviews.count),
        0
      );
      setReviewsSummary({
        rating: round(ratingsSum / tripsCount, 1),
        count,
      });
    }
  }, [tripsData]);

  useEffect(() => {
    const params = queryString.parse(router.asPath.split(/\?/)[1]);
    const allActivityTagsClone = allActivityTags ? [...allActivityTags] : [];
    const allRegionsTagsClone = allRegions ? [...allRegions] : [];
    let regionCountry: any[] = [];
    const activitiesParams: string[] =
      params && params.activities && typeof params.activities === 'string'
        ? params.activities.split(',')
        : [];

    const activitiesToSet = allActivityTagsClone?.filter(
      (item) =>
        activitiesParams.findIndex((routerAct) => {
          return (
            routerAct?.replace(/-/g, ' ').replace(/and/g, '&') ===
            item.value.toLowerCase()
          );
        }) > -1
    );
    if (activitiesToSet) {
      setActivityTags([...activityTags, ...activitiesToSet]);
    }

    const regionsParams: string[] =
      params && params.regions && typeof params.regions === 'string'
        ? params.regions.split(',')
        : [];

    const regionsToSet = allRegionsTagsClone?.filter(
      (item) =>
        regionsParams.findIndex(
          (routerReg) =>
            routerReg?.replace(/-/g, ' ') === item.title.toLowerCase()
        ) > -1
    );

    if (regionsToSet) {
      for (const reg of regionsToSet) {
        for (const dest of reg.options) {
          regionCountry.push(dest);
        }
      }
    }

    const countryParams: string[] =
      params && params.countries && typeof params.countries === 'string'
        ? params.countries.split(',')
        : [];

    allRegionsTagsClone?.forEach((item) => {
      item.options.forEach((option) => {
        if (
          countryParams.findIndex(
            (routerReg) =>
              routerReg?.replace(/-/g, ' ') === option.title.toLowerCase()
          ) > -1
        ) {
          regionCountry.push(option);
        }
      });
    });

    if (regionCountry.length > 0) {
      setDestinations(regionCountry);
    }

    // eslint-disable-next-line
  }, [router, router.asPath, allActivityTags, allRegions]);

  return (
    <div>
      <AboveTheFold
        alt={division.type}
        cover={cover}
        description={division.content}
      />
      {tripsData && tripsData.trips.items.length ? (
        <div className="max-w-6.75xl mx-auto">
          <div className="my-12 lg:mx-10">
            <Trips
              headerText={`Our ${division.name} trips`}
              reviewsSummary={reviewsSummary}
              trips={tripsData.trips.items}
              withReviewsSummary={true}
              division={division}
              queryParamActivityTags={activityTags}
              queryParamDestinations={destinations}
              hasSmallerBottomMarginOnTitle
            />
          </div>
        </div>
      ) : null}

      <div className="md:py-4" />
      <div className="mx-5">
        <h2 className="md:hidden text-4xl leading-9 mb-2.5 text-dark-900 font-semibold">
          {`Straight from the ‘gram`}
        </h2>
      </div>
      <Gallery
        tags={[
          getDivisionNameForTripQuery(division.internalName) || '',
          'Generic',
        ]}
        min={10}
        data={galleryData}
      />
      <div className="py-8" />
      <WhyGoGlobal />
      <BlogDivision data={dataBlog} />
      <div className="py-10" />
    </div>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { division } = context.params as { division: string };

  const apolloClient = initializeApollo();

  await apolloClient.query({
    query: REGIONS_QUERY,
  });

  await apolloClient.query({
    query: DIVISIONS_QUERY,
  });

  const { data: tripsData } = await apolloClient.query({
    query: TRIPS,
    variables: {
      order: 'popularity',
      divisions: [getDivisionNameForTripQuery(getInternalName(division))],
    },
  });

  const { data } = await apolloClient.query<{ divisions: Division[] }>({
    query: DIVISIONS_QUERY,
  });

  const divisionData = data.divisions.find((d) => d.internalName === division);

  const { data: dataBlog } = await apolloClient.query<BlogData>({
    query: GET_BLOGPOST_QUERY,
    variables: {
      limit: 3,
      tags: divisionData?.internalName
        ? [getDivisionNameForTripQuery(divisionData?.internalName)]
        : undefined,
    },
  });

  const navigationData = await navigationDataFn(apolloClient);
  const headerData = await getHeaderData(apolloClient);
  const { data: galleryData } = await apolloClient.query<any>({
    query: GALLERY_QUERY,
    variables: {
      tags: [
        divisionData?.internalName
          ? getDivisionNameForTripQuery(divisionData?.internalName)
          : '',
        'Generic',
      ],
      min: 10,
      max: 10,
    },
  });

  const { data: activityTagsData } = await apolloClient.query<ActivityTagData>({
    query: ACTIVITY_TAGS_BY_DIVISION_ID,
    variables: {
      divisionId: divisionData?.id,
    },
  });

  const metaData = await getMeta(apolloClient, `/${division}`);
  const meta = {
    ...metaData,
    image: generateUrl(
      BannerPathFactory.fromDivision(divisionData?.type || ''),
      undefined,
      undefined,
      'banner-md'
    ),
  };

  return {
    props: {
      internalName: divisionData?.internalName,
      division: divisionData,
      meta,
      tripsData,
      dataBlog,
      navigationData,
      headerData,
      galleryData,
      activityTagsData,
    },
    revalidate: 3600,
    notFound: !divisionData,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const apolloClient = initializeApollo();

  const {
    data: { divisions },
  } = await apolloClient.query({ query: DIVISIONS_QUERY });

  await apolloClient.query({
    query: REGIONS_QUERY,
  });

  const paths = divisions.map((division: any) => {
    return `/${division.internalName}`;
  });

  return {
    paths,
    fallback: false,
  };
};

export default Division;
