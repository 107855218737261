import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../checkbox';
import { CSSTransition } from 'react-transition-group';
import { MultiValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import { TSimpleFilter } from '../trip-filters';

interface TripFiltersModalProps {
  selectedDestinationOptions: MultiValue<TDropDownItem>;
  setSelectedDestionationOptions: Function;
  selectedDurationOptions: MultiValue<TDropDownItem>;
  setSelectedDurationOptions: Function;
  selectedAgeOptions: MultiValue<TDropDownItem>;
  setSelectedAgeOptions: Function;
  selectedActivityOptions: MultiValue<TDropDownItem>;
  setSelectedActivityOptions: Function;
  destinations: MultiValue<TDropDownItem>;
  durations: TSimpleFilter[];
  ages: TSimpleFilter[];
  activityTags: TSimpleFilter[];
  isVisible: boolean;
  toggleVisibility: Function;
  numberOfFilteredTrips: number;
  filteredTripsLoading: boolean;
  selectedProviderOptions: MultiValue<TDropDownItem>;
  setSelectedProviderOptions: Function;
  allProviders: TSimpleFilter[];
}

function TripFiltersModal({
  selectedDestinationOptions,
  setSelectedDestionationOptions,
  selectedDurationOptions,
  setSelectedDurationOptions,
  selectedAgeOptions,
  setSelectedAgeOptions,
  selectedActivityOptions,
  setSelectedActivityOptions,
  destinations,
  durations,
  ages,
  activityTags,
  isVisible,
  toggleVisibility,
  numberOfFilteredTrips,
  filteredTripsLoading,
  selectedProviderOptions,
  setSelectedProviderOptions,
  allProviders,
}: TripFiltersModalProps): JSX.Element {
  const close = () => {
    toggleVisibility();
  };

  useEffect(() => {
    return () => {
      // make sure overflow-hidden is removed from body once dismounted
      // this usually happens when user clicks on browser back button instead
      // of closing the dialog like a normal human being!!! Just kidding though!
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  function clearAllFilters() {
    setSelectedDestionationOptions([]);
    setSelectedDurationOptions([]);
    setSelectedAgeOptions([]);
    setSelectedActivityOptions([]);
  }

  function toggleItem(
    itemToToggle: TDropDownItem,
    selectedItems: MultiValue<TDropDownItem>,
    setSelectedItems: Function
  ) {
    if (selectedItems.find((item) => item.value === itemToToggle.value)) {
      setSelectedItems(
        selectedItems.filter((item) => item.value !== itemToToggle.value)
      );
    } else {
      setSelectedItems([...selectedItems, itemToToggle]);
    }
  }

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="filter-translatey"
      unmountOnExit
    >
      <div className={'fixed inset-0 z-350 w-full max-h-full bg-light-600 '}>
        <div className="relative max-h-full flex-1-only flex">
          <div className="fixed inset-0 w-full h-fit py-3.5 px-5 flex justify-between items-center bg-light-600 border-b border-light-800">
            <div className="text-lg leading-5 font-bold">Filter results</div>
            <div className="flex items-center gap-5">
              <div
                className="text-base leading-5 font-bold text-teal-900 cursor-pointer"
                onClick={() => clearAllFilters()}
              >
                Clear all
              </div>
              <button className="cursor-pointer pt-1" onClick={close}>
                <i className="icon-close"></i>
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-14 px-8 pb-9 divide-y flex-1-only overflow-scroll">
            <>
              <div className="py-8 border-light-900">
                <h1 className="text-lg leading-5 font-bold text-dark-900 mb-5">
                  Destination
                </h1>
                {destinations.map((destination, index) => (
                  <div
                    key={index}
                    className="box-border text-sm leading-4 w-full flex items-center justify-between py-2.5 text-dark-900 cursor-pointer select-none"
                    onClick={() =>
                      toggleItem(
                        destination,
                        selectedDestinationOptions,
                        setSelectedDestionationOptions
                      )
                    }
                  >
                    <div>{destination.title}</div>
                    <Checkbox
                      isChecked={
                        Boolean(
                          selectedDestinationOptions.find((elem) => {
                            return elem.value === destination.value;
                          })
                        ) || false
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="py-8 border-light-900">
                <h1 className="text-lg leading-5 font-bold text-dark-900 mb-5">
                  Duration
                </h1>
                {durations.map((duration, index) => (
                  <div
                    key={index}
                    className="box-border text-sm leading-4 w-full flex items-center justify-between py-2.5 text-dark-900 cursor-pointer select-none"
                    onClick={() =>
                      toggleItem(
                        duration,
                        selectedDurationOptions,
                        setSelectedDurationOptions
                      )
                    }
                  >
                    <div>{duration.title}</div>
                    <Checkbox
                      isChecked={
                        Boolean(
                          selectedDurationOptions.find((elem) => {
                            return elem.value === duration.value;
                          })
                        ) || false
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="py-8 border-light-900">
                <h1 className="text-lg leading-5 font-bold text-dark-900 mb-5">
                  Age
                </h1>
                {ages.map((age, index) => (
                  <div
                    key={index}
                    className="box-border text-sm leading-4 w-full flex items-center justify-between py-2.5 text-dark-900 cursor-pointer select-none"
                    onClick={() =>
                      toggleItem(age, selectedAgeOptions, setSelectedAgeOptions)
                    }
                  >
                    <div>{age.title}</div>
                    <Checkbox
                      isChecked={
                        Boolean(
                          selectedAgeOptions.find((elem) => {
                            return elem.value === age.value;
                          })
                        ) || false
                      }
                    />
                  </div>
                ))}
              </div>
              {activityTags.length !== 0 ? (
                <div className="py-8 border-light-900">
                  <h1 className="text-lg leading-5 font-bold text-dark-900 mb-5">
                    Activities
                  </h1>
                  {activityTags.map((activity, index) => (
                    <div
                      key={index}
                      className="box-border text-sm leading-4 w-full flex items-center justify-between py-2.5 text-dark-900 cursor-pointer select-none"
                      onClick={() =>
                        toggleItem(
                          activity,
                          selectedActivityOptions,
                          setSelectedActivityOptions
                        )
                      }
                    >
                      <div>{activity.title}</div>
                      <Checkbox
                        isChecked={
                          Boolean(
                            selectedActivityOptions.find((elem) => {
                              return elem.value === activity.value;
                            })
                          ) || false
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              {allProviders.length !== 0 ? (
                <div className="py-8 border-light-900">
                  <h1 className="text-lg leading-5 font-bold text-dark-900 mb-5">
                    Operator
                  </h1>
                  {allProviders.map((provider, index) => (
                    <div
                      key={index}
                      className="box-border text-sm leading-4 w-full flex items-center justify-between py-2.5 text-dark-900 cursor-pointer select-none"
                      onClick={() =>
                        toggleItem(
                          provider,
                          selectedProviderOptions,
                          setSelectedProviderOptions
                        )
                      }
                    >
                      <div>{provider.title}</div>
                      <Checkbox
                        isChecked={
                          Boolean(
                            selectedProviderOptions.find((elem) => {
                              return elem.value === provider.value;
                            })
                          ) || false
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              <button
                className="bg-red-900 text-light-600 py-3 text-center text-lg leading-6 font-bold w-full select-none rounded-md mb-14"
                onClick={close}
              >
                Show {filteredTripsLoading ? '...' : numberOfFilteredTrips}{' '}
                trips
              </button>
            </>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export { TripFiltersModal };
