import { BasicTrip } from '@/Lib/types/trip';
import { ProductCard, ProductCardSkeleton } from '../cards/product-card';
import { TripFilters } from '../division/trip-filters';
import { GStarRating } from '../g-star-rating';
import { TDropDownItem } from '../drop-down/types';
import { MultiValue } from 'react-select';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getDivisionNameForTripQuery } from '@/Pages/[division]';
import { Division } from '@/Lib/types/division';
import { TRIPS } from '@/Lib/graphql/queries/trip.query';
import uniqBy from 'lodash/uniqBy';

interface TripsProps {
  headerText: string;
  subHeaderText?: string;
  trips?: BasicTrip[];
  withReviewsSummary?: boolean;
  reviewsSummary?: any;
  division?: Division;
  isLoading?: boolean;
  queryParamActivityTags?: TDropDownItem[];
  queryParamDestinations?: TDropDownItem[];
  hasSmallerBottomMarginOnTitle?: boolean;
  isFromDestination?: boolean;
  clickProductCard?: (index: number, trip: BasicTrip) => void;
}

function Trips({
  headerText,
  subHeaderText,
  trips,
  withReviewsSummary = false,
  reviewsSummary,
  division,
  queryParamActivityTags,
  queryParamDestinations,
  isLoading = false,
  hasSmallerBottomMarginOnTitle = false,
  isFromDestination = false,
  clickProductCard,
}: TripsProps): JSX.Element {
  const [selectedDestinationOptions, setSelectedDestionationOptions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedDurationOptions, setSelectedDurationOptions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedAgeOptions, setSelectedAgeOptions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedActivityOptions, setSelectedActivityOptions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedProviderOptions, setSelectedProviderOptions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [allProviders, setAllProviders] = useState<TDropDownItem[]>([]);
  const [tripsData, setTripsData] = useState(trips);

  useEffect(() => {
    const providers = trips?.map((trip) => trip.partner) || [];
    const uniqueProviders = uniqBy(providers, 'id');
    const structuredProviders = uniqueProviders.map((provider) => ({
      value: String(provider?.id),
      title: provider?.name,
    }));
    setAllProviders(structuredProviders || []);
  }, [trips]);

  useEffect(() => {
    setTripsData(trips);
  }, [trips]);

  const variables = {
    order: 'popularity',
    divisions: division
      ? [getDivisionNameForTripQuery(String(division?.internalName))]
      : [],
    destinations: [...selectedDestinationOptions.map((item) => item.title)],
    durations: [...selectedDurationOptions.map((item) => item.value)],
    ages: [...selectedAgeOptions.map((item) => item.value)],
    activity_tags: [...selectedActivityOptions.map((item) => item.value)],
    partnerIds: [...selectedProviderOptions.map((item) => Number(item.value))],
  };

  let [
    filterTrips,
    { loading: filteredTripsLoading, data: filteredTripsData },
  ] = useLazyQuery<{ trips: { items: BasicTrip[] } }>(TRIPS, {
    variables,
  });

  useEffect(() => {
    if (isFromDestination) {
      if (selectedActivityOptions.length) {
        filterTrips();
      } else {
        setTripsData(trips);
      }
    } else {
      if (
        selectedDurationOptions.length ||
        selectedDestinationOptions.length ||
        selectedActivityOptions.length ||
        selectedAgeOptions.length ||
        selectedProviderOptions.length
      ) {
        filterTrips();
      } else {
        setTripsData(trips);
      }
    }
  }, [
    selectedDurationOptions,
    selectedDestinationOptions,
    selectedActivityOptions,
    selectedAgeOptions,
    selectedProviderOptions,
  ]);

  useEffect(() => {
    if (filteredTripsData?.trips.items.length) {
      setTripsData(filteredTripsData.trips.items);
    }
  }, [filteredTripsData]);

  useEffect(() => {
    if (queryParamActivityTags) {
      setSelectedActivityOptions(queryParamActivityTags);
    }
    if (queryParamDestinations) {
      setSelectedDestionationOptions(queryParamDestinations);
    }
  }, [queryParamActivityTags, queryParamDestinations]);

  return (
    <div>
      <h2
        className={
          'text-3xl leading-8 font-bold mx-5 md:mx-0 text-dark-900' +
          (hasSmallerBottomMarginOnTitle ? ' mb-2' : ' mb-5')
        }
      >
        {headerText}
      </h2>
      {subHeaderText ? (
        <div className="text-lg leading-6 text-dark-700 mb-4 px-5 md:px-0">
          {subHeaderText}
        </div>
      ) : null}
      {withReviewsSummary && trips ? (
        <div className="max-w-6.75xl mx-auto px-5 md:px-0">
          {reviewsSummary.count > 0 ? (
            <div className="space-x-2 text-gray-500 flex flex-row justify-start items-center">
              <span className="font-bold">{reviewsSummary.rating}</span>
              <GStarRating rating={reviewsSummary.rating} />
              <span>
                rating from {reviewsSummary.count?.toLocaleString()} reviews
              </span>
            </div>
          ) : null}

          <TripFilters
            trips={trips}
            numberOfFilteredTrips={tripsData?.length || 0}
            filteredTripsLoading={filteredTripsLoading}
            selectedDestinationOptions={selectedDestinationOptions}
            setSelectedDestionationOptions={setSelectedDestionationOptions}
            selectedDurationOptions={selectedDurationOptions}
            setSelectedDurationOptions={setSelectedDurationOptions}
            selectedAgeOptions={selectedAgeOptions}
            setSelectedAgeOptions={setSelectedAgeOptions}
            selectedActivityOptions={selectedActivityOptions}
            setSelectedActivityOptions={setSelectedActivityOptions}
            allProviders={allProviders}
            selectedProviderOptions={selectedProviderOptions}
            setSelectedProviderOptions={setSelectedProviderOptions}
            division={division}
          />
        </div>
      ) : null}
      <div
        className="max-w-6.75xl mx-auto px-5 md:px-0 grid gap-5 overflow-scroll pb-2 lg:grid-cols-4-1fr lg:overflow-visible hide-webkit-scrollbar"
        style={{
          gridTemplateColumns: `repeat(${
            withReviewsSummary
              ? filteredTripsLoading
                ? 10
                : tripsData?.length
              : trips
              ? trips.length
              : 10
          }, 16.7rem)`,
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {withReviewsSummary && !filteredTripsLoading && tripsData
          ? tripsData.map((trip, index) => (
              <ProductCard trip={trip} key={index} />
            ))
          : filteredTripsLoading
          ? [...Array(4)].map((_, index) => <ProductCardSkeleton key={index} />)
          : null}
        {!withReviewsSummary && !filteredTripsLoading && tripsData
          ? tripsData.map((trip, index) => (
              <ProductCard
                trip={trip}
                key={index}
                clickProductCard={() =>
                  clickProductCard && clickProductCard(index, trip)
                }
              />
            ))
          : filteredTripsLoading
          ? [...Array(4)].map((_, index) => <ProductCardSkeleton key={index} />)
          : null}
      </div>
    </div>
  );
}

export { Trips };
