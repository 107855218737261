import Blog from '@/Components/blog';
import { BlogData } from '@/Lib/types/blog';

type props = {
  // division?: string;
  data: BlogData;
};

function BlogDivision({ data }: props): JSX.Element {
  return (
    <section className="max-w-6.75xl xl:mx-auto lg:mt-20 mt-14">
      <h2 className="font-bold text-3xl leading-8 mb-5 mx-5 md:mx-10 sm:mx-7 text-dark-900">
        Travel inspiration{' '}
      </h2>
      <div className="grid grid-cols-3-15rem overflow-scroll hide-webkit-scrollbar lg:grid-cols-3 gap-5 px-5 sm:pl-7 md:px-10">
        <Blog data={data} />
      </div>
    </section>
  );
}

export default BlogDivision;
