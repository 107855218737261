import { useState, Fragment, useEffect } from 'react';
import { TDropDownGroup, TDropDownItem } from './types';
import Select, {
  components,
  ControlProps,
  MultiValueGenericProps,
  GroupProps,
  MultiValue,
  MenuListProps,
  GroupBase,
} from 'react-select';
import { Checkbox } from '../checkbox';
import { GImage } from '@/Components/g-image';
import { commonStyles } from './commonStyles';

type DropdownMultivalueGroupedProps = {
  id: string;
  options: Array<TDropDownGroup>;
  dropdownName: string;
  placeholder: string;
  icon: string;
  selectedOptions: MultiValue<TDropDownItem>;
  setSelectedOptions: Function;
};

const Control = ({
  children,
  ...props
}: ControlProps<TDropDownGroup, true>) => {
  // @ts-ignore
  const { icon } = props.selectProps;

  return (
    <components.Control {...props}>
      <i className={`${icon} ml-2 mr-1 text-xl`}></i>
      {children}
    </components.Control>
  );
};

const MenuList = (
  props: MenuListProps<TDropDownGroup, true, GroupBase<TDropDownGroup>>
) => {
  // @ts-ignore
  const { clearAllItemsAndGroups } = props.selectProps;

  return (
    <Fragment>
      <div
        className="text-teal-800 text-right p-2 cursor-pointer border-b"
        onClick={clearAllItemsAndGroups}
      >
        clear all
      </div>
      <components.MenuList<TDropDownGroup, true, GroupBase<TDropDownGroup>>
        {...props}
      >
        {props.children}
      </components.MenuList>
    </Fragment>
  );
};

const MultiValue = (props: MultiValueGenericProps<TDropDownGroup, true>) => {
  // @ts-ignore
  let { options, selectedOptions } = props.selectProps;
  let castedOptions = options as unknown as TDropDownGroup[];

  return (
    <div>
      {props.data.value === selectedOptions[0]?.value ? (
        <div className="flex items-center gap-1.5">
          <div className="w-5 h-5">
            <GImage
              path={
                castedOptions.filter(
                  (i: TDropDownGroup) => i.value === selectedOptions[0].group
                )[0].image || ''
              }
              alt={
                castedOptions.filter(
                  (i: TDropDownGroup) => i.value === selectedOptions[0].group
                )[0].title
              }
              classes="rounded-full"
              width="100%"
              height="100%"
              crop_w={300}
              crop_h={300}
            />
          </div>
          {selectedOptions.length > 1 ? (
            <div className="max-w-full max-h-6 overflow-hidden whitespace-nowrap text-sm">
              {selectedOptions[0].title} & {selectedOptions.length - 1} more...
            </div>
          ) : (
            <div className="text-sm">{selectedOptions[0].title}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const Group = ({ data, ...props }: GroupProps<TDropDownGroup, true>) => {
  // @ts-ignore
  const { selectedGroups, toggleGroup } = props.selectProps;

  const castedData = data as unknown as TDropDownGroup;
  return (
    <div>
      <div
        className="flex justify-between items-center my-2.5 py-2 px-2.5 cursor-pointer transition hover:bg-dropDownBack gap-4"
        onClick={() => toggleGroup(castedData)}
      >
        <div className="h-16 w-16">
          <GImage
            path={castedData.image || ''}
            alt={castedData.title}
            classes="rounded-full"
            width="100%"
            height="100%"
            crop_w={300}
            crop_h={300}
          />
        </div>
        <h2 className="text-base leading-4 font-semibold flex-grow ">
          {castedData.title}
        </h2>
        <Checkbox
          isChecked={
            Boolean(
              selectedGroups.find((elem: TDropDownGroup) => {
                return elem.value === castedData.value;
              })
            ) || false
          }
        />
      </div>
      <components.Group {...{ data: data, ...props }} />
    </div>
  );
};

function DropdownMultivalueGrouped({
  id,
  options,
  dropdownName,
  placeholder,
  icon,
  selectedOptions,
  setSelectedOptions,
}: DropdownMultivalueGroupedProps): JSX.Element {
  const [selectedGroups, setSelectedGroups] = useState<
    MultiValue<TDropDownGroup>
  >([]);

  useEffect(() => {
    let groupsThatShouldBeSelected = [];
    for (const group of options) {
      let shouldGroupBeSelected = true;
      for (const groupOption of group.options) {
        if (
          selectedOptions.findIndex(
            (item) => item.value === groupOption.value
          ) === -1
        ) {
          shouldGroupBeSelected = false;
          // break;
        }
      }
      if (shouldGroupBeSelected) {
        groupsThatShouldBeSelected.push(group);
      }
    }
    setSelectedGroups(groupsThatShouldBeSelected);
  }, [selectedOptions]);

  const toggleGroup = (group: TDropDownGroup) => {
    const index = selectedGroups.find((g) => g.value === group.value);
    if (index) {
      setSelectedGroups(
        selectedGroups.filter((element) => element.value !== group.value)
      );
      setSelectedOptions(
        selectedOptions.filter((selected) => {
          return !Boolean(
            group.options.find(
              (groupOption) => selected.value === groupOption.value
            )
          );
        })
      );
    } else {
      setSelectedGroups([...selectedGroups, group]);
      const newSelectedOptions: TDropDownItem[] = group.options.filter(
        (option) => {
          return !selectedOptions.find(
            (selected) => selected.value === option.value
          );
        }
      );
      setSelectedOptions([...selectedOptions, ...newSelectedOptions]);
    }
  };

  const clearAllItemsAndGroups = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSelectedGroups([]);
    setSelectedOptions([]);
  };

  const handleChange = (selectedOptions: MultiValue<TDropDownItem>) => {
    let groupsThatShouldBeSelected = [];
    for (const group of options) {
      let shouldGroupBeSelected = true;
      for (const groupOption of group.options) {
        if (
          selectedOptions.findIndex(
            (item) => item.value === groupOption.value
          ) === -1
        ) {
          shouldGroupBeSelected = false;
          // break;
        }
      }
      if (shouldGroupBeSelected) {
        groupsThatShouldBeSelected.push(group);
      }
    }
    setSelectedOptions(selectedOptions);
    setSelectedGroups(groupsThatShouldBeSelected);
  };

  function renderOptions(item: TDropDownItem) {
    return (
      <div className="pl-5 pr-2.5 py-2 flex gap-2 items-center cursor-pointer">
        <Checkbox
          small={true}
          isChecked={
            Boolean(
              selectedOptions.find((elem) => {
                return elem.value === item.value;
              })
            ) || false
          }
        />
        <p className="text-base leading-4">{item.title}</p>
      </div>
    );
  }

  return (
    <Select
      id={id}
      instanceId={id}
      components={{ MultiValue, Control, Group, MenuList }}
      styles={commonStyles}
      value={selectedOptions as unknown as TDropDownGroup}
      isMulti
      isSearchable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleChange}
      // isLoading={isLoading} TODO: add when data is available
      name={dropdownName}
      options={options}
      formatOptionLabel={renderOptions}
      placeholder={placeholder}
      // custom props
      // @ts-ignore
      clearAllItemsAndGroups={clearAllItemsAndGroups}
      selectedOptions={selectedOptions}
      selectedGroups={selectedGroups}
      toggleGroup={toggleGroup}
      icon={icon}
    />
  );
}

export { DropdownMultivalueGrouped };
