import { gql } from '@apollo/client';

export const ACTIVITY_TAGS_BY_DIVISION_ID = gql`
  query ActivityTagsByDivisionID($divisionId: Int!) {
    activity_tags_by_division_id(divisionId: $divisionId) {
      id
      name
    }
  }
`;

export const activity_tags = gql`
  query activity_tags {
    activity_tags {
      id
      name
    }
  }
`;
