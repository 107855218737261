import { WhyGoGlobalData } from './types';

const whyGoGlobalData: WhyGoGlobalData = {
  title: 'Why go Global',
  items: [
    {
      icon: 'website/icons/why-go-global/worldwide-support.svg',
      title: 'Worldwide support',
      description:
        'Feel confident knowing that someone is always ready to assist throughout the journey & they’re only a call, tweet, comment, text or double tap away, 24/7.',
    },
    {
      icon: 'website/icons/why-go-global/experts.svg',
      title: 'We’re the experts',
      description:
        'We don’t just say that to brag. With over 10 years in the industry and a team of over 100 passionate pros, you can bet we know our stuff better than anyone else.',
    },
    {
      icon: 'website/icons/why-go-global/partner.svg',
      title: 'Partner network',
      description:
        'With over 1,200 partners around the globe, we have access to plenty of exclusive opportunities to make your trip the best it can be.',
    },
    {
      icon: 'website/static/gworld/piggy-bank.svg',
      title: 'Payment plans',
      description:
        'There’s no reason you can’t live out your travel dreams! If your funds are low but your desire to travel and see the world is high, don’t stress. You can pay off your trip in any instalments that you get to choose.',
    },
    {
      icon: 'website/icons/price-summary/lifetime-deposit-guarantee.svg',
      title: 'Lifetime Deposit Guarantee',
      description:
        'We’re proud to offer our Lifetime Deposit Guarantee for when things don’t go according to plan. Change your travel dates for when you’re ready, or choose a totally different trip of ours. You can even gift your deposit to a friend.',
    },
    {
      icon: 'website/icons/why-go-global/flexible-trips.svg',
      title: 'Flexible trips',
      description:
        'We understand that the unexpected happens sometimes. Can’t travel right now? Swap your trip, gift it or save it for later with our Lifetime Deposit Guarantee.',
    },
  ],
};

export { whyGoGlobalData };
