import { FC } from 'react';
import { generateUrl, GImage } from '@/Components/g-image';
import { useTransformationBasedOnWindowSize } from '@/Lib/hooks/useTransformationBasedOnWindowSize';
import Head from 'next/head';

import styles from './above-the-fold.module.css';

type props = {
  alt: string;
  cover: string;
  description: string;
};

const AboveTheFold: FC<props> = ({ alt, cover, description }) => {
  return (
    <div>
      <Head>
        <link
          rel="preload"
          href={generateUrl(
            cover,
            undefined,
            undefined,
            useTransformationBasedOnWindowSize()
          )}
          as="image"
        />
      </Head>
      <div className="h-80 md:h-100">
        <GImage
          path={cover}
          transformation={useTransformationBasedOnWindowSize()}
          alt={alt}
          height="100%"
          width="100%"
          hasLoadingBackground
        />
      </div>
      <section className="max-w-6.75xl mx-auto">
        <div className="my-12 mx-5 lg:mx-10">
          <div
            className={`text-lg leading-6 text-dark-700 ${styles.divisionInfo}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <hr className="block my-12 h-px border-t border-light-900" />
        </div>
      </section>
    </div>
  );
};

export { AboveTheFold };
