import { useEffect, useState } from 'react';
import { TripFiltersModal } from '../trip-filters-modal';
import { durationFilters, ageFilters } from './data';
import { TDropDownGroup, TDropDownItem } from '../../drop-down/types';
import { MultiValue } from 'react-select';
import { BasicTrip } from '@/Lib/types/trip';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useQuery } from '@apollo/client';
import { Division } from '@/Lib/types/division';
import { ACTIVITY_TAGS_BY_DIVISION_ID } from '@/Lib/graphql/queries/activity-tags.query';
import { ActivityTagsByDivisionId } from '@/Lib/types/activity-tags';
import { DropdownMultivalueGrouped } from '@/Components/drop-down/multivalue-grouped';
import { DropdownMultivalue } from '@/Components/drop-down/multivalue';
import TourIconSVG from '@/Components/search-panel/search-panel-tabs/tour-icon-svg';

type TripFiltersProps = {
  selectedDestinationOptions: MultiValue<TDropDownItem>;
  setSelectedDestionationOptions: Function;
  selectedDurationOptions: MultiValue<TDropDownItem>;
  setSelectedDurationOptions: Function;
  selectedAgeOptions: MultiValue<TDropDownItem>;
  setSelectedAgeOptions: Function;
  selectedActivityOptions: MultiValue<TDropDownItem>;
  setSelectedActivityOptions: Function;
  allProviders: TDropDownItem[];
  selectedProviderOptions: MultiValue<TDropDownItem>;
  setSelectedProviderOptions: Function;
  division?: Division;
  trips: BasicTrip[];
  numberOfFilteredTrips: number;
  filteredTripsLoading: boolean;
};

export type TSimpleFilter = {
  value: string;
  title: string;
};

export function getGroupedRegionsAndDestinations(trips: BasicTrip[]) {
  const groupedRegionsAndDestinations: TDropDownGroup[] = [];

  const regionsAndDestionations = trips.map((trip) => ({
    region: trip.region,
    destination: trip.destination,
  }));
  for (let index in regionsAndDestionations) {
    const foundIndex = groupedRegionsAndDestinations.findIndex(
      (item) => item.title === regionsAndDestionations[index].region
    );
    if (foundIndex >= 0) {
      if (
        !groupedRegionsAndDestinations[foundIndex].options.find(
          (item) => item.title === regionsAndDestionations[index].destination
        )
      ) {
        groupedRegionsAndDestinations[foundIndex].options.push({
          title: regionsAndDestionations[index].destination,
          value: getInternalName(regionsAndDestionations[index].destination),
          group: getInternalName(regionsAndDestionations[index].region),
        });
      }
    } else {
      groupedRegionsAndDestinations.push({
        title: regionsAndDestionations[index].region,
        value: getInternalName(regionsAndDestionations[index].region),
        image: `new-website/region/${getInternalName(
          regionsAndDestionations[index].region
        )}.webp`,
        options: [
          {
            title: regionsAndDestionations[index].destination,
            value: getInternalName(regionsAndDestionations[index].destination),
            group: getInternalName(regionsAndDestionations[index].region),
          },
        ],
      });
    }
  }
  return groupedRegionsAndDestinations;
}

function getDestinations(trips: BasicTrip[]) {
  const destinations = trips.map((trip) => ({
    title: trip.destination,
    value: getInternalName(trip.destination),
    group: getInternalName(trip.region),
  }));

  let uniqueDestinations: TSimpleFilter[] = [];
  for (const destination of destinations) {
    if (!uniqueDestinations.find((item) => item.value === destination.value)) {
      uniqueDestinations.push(destination);
    }
  }

  return uniqueDestinations;
}

function getDurations(trips: BasicTrip[]) {
  const durations = trips.map((trip) => ({
    title: `${trip.duration.min}-${trip.duration.max} ${trip.durationType}s`,
    value: `${trip.duration.min}-${trip.duration.max} ${trip.durationType}s`,
    data: {
      min: Number(trip.duration.min),
      max: Number(trip.duration.max),
      type: trip.durationType,
    },
  }));

  const filteredDurations = durationFilters.filter((d) =>
    durations.find(
      (du) =>
        d.data[du.data.type] &&
        ((du.data.min >= d.data[du.data.type].min &&
          du.data.min <= d.data[du.data.type].max) ||
          (du.data.max >= d.data[du.data.type].min &&
            du.data.max <= d.data[du.data.type].max))
    )
  );

  const mappedDurations: { value: string; title: string }[] =
    filteredDurations.map((item) => ({
      value: item.value,
      title: item.title,
    }));

  return mappedDurations;
}

function getAges(trips: BasicTrip[]) {
  const ages = trips.map((trip) => ({
    title: `${trip.age.min}-${trip.age.max} years old`,
    value: `${trip.age.min}-${trip.age.max}`,
    data: {
      min: trip.age.min,
      max: trip.age.max,
    },
  }));

  const tripMaxAge = Math.max(...ages.map((i) => Number(i.data.max)));
  const tripMinAge = Math.min(...ages.map((i) => Number(i.data.min)));
  const filteredAges = ageFilters.filter(
    (a) =>
      (a.data.min >= tripMinAge && a.data.min <= tripMaxAge) ||
      (a.data.max >= tripMinAge && a.data.max <= tripMaxAge)
  );

  const mappedAges: { value: string; title: string }[] = filteredAges.map(
    (item) => ({
      value: item.value,
      title: item.title,
    })
  );
  return mappedAges;
}

export function getActivityTags(
  data:
    | {
        activity_tags_by_division_id: ActivityTagsByDivisionId[];
      }
    | undefined
) {
  if (!data) return [];
  const mappedData = data.activity_tags_by_division_id.map((item) => ({
    title: item.name,
    value: item.name,
  }));
  return mappedData;
}

function TripFilters({
  selectedDestinationOptions,
  setSelectedDestionationOptions,
  selectedDurationOptions,
  setSelectedDurationOptions,
  selectedAgeOptions,
  setSelectedAgeOptions,
  selectedActivityOptions,
  setSelectedActivityOptions,
  allProviders,
  selectedProviderOptions,
  setSelectedProviderOptions,
  division,
  trips,
  numberOfFilteredTrips,
  filteredTripsLoading,
}: TripFiltersProps): JSX.Element {
  const [isMobileFiltersVisible, setIsMobileFiltersVisible] = useState(false);

  const { data: activityTagsData } = useQuery<{
    activity_tags_by_division_id: ActivityTagsByDivisionId[];
  }>(ACTIVITY_TAGS_BY_DIVISION_ID, {
    variables: {
      divisionId: division?.id,
    },
  });

  // transform trip filters for drop-down component
  const groupedRegionsAndDestinations = getGroupedRegionsAndDestinations(trips);
  const durations: TSimpleFilter[] = getDurations(trips);
  const ages: TSimpleFilter[] = getAges(trips);
  const activityTags: TSimpleFilter[] = getActivityTags(activityTagsData);
  const destinations: TSimpleFilter[] = getDestinations(trips);

  useEffect(() => {
    if (isMobileFiltersVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isMobileFiltersVisible]);

  return (
    <div>
      <div className="flex my-5 md:hidden">
        <button
          className="border border-dark-700 rounded-full py-2 px-5 text-base leading-4"
          onClick={() => setIsMobileFiltersVisible(true)}
        >
          <i className="icon-filters mr-3"></i>filters
        </button>
      </div>

      <TripFiltersModal
        isVisible={isMobileFiltersVisible}
        toggleVisibility={() => setIsMobileFiltersVisible(false)}
        selectedDestinationOptions={selectedDestinationOptions}
        setSelectedDestionationOptions={setSelectedDestionationOptions}
        selectedDurationOptions={selectedDurationOptions}
        setSelectedDurationOptions={setSelectedDurationOptions}
        selectedAgeOptions={selectedAgeOptions}
        setSelectedAgeOptions={setSelectedAgeOptions}
        selectedActivityOptions={selectedActivityOptions}
        setSelectedActivityOptions={setSelectedActivityOptions}
        selectedProviderOptions={selectedProviderOptions}
        setSelectedProviderOptions={setSelectedProviderOptions}
        allProviders={allProviders}
        destinations={destinations}
        durations={durations}
        ages={ages}
        activityTags={activityTags}
        numberOfFilteredTrips={numberOfFilteredTrips}
        filteredTripsLoading={filteredTripsLoading}
      />

      <div className="hidden md:grid grid-cols-4 gap-5 mt-6 mb-4">
        <DropdownMultivalueGrouped
          id="trip-filter-destination"
          selectedOptions={selectedDestinationOptions}
          setSelectedOptions={setSelectedDestionationOptions}
          icon="icon-location"
          placeholder="Destination"
          options={groupedRegionsAndDestinations}
          dropdownName="Location"
        />
        <DropdownMultivalue
          id="trip-filter-duration"
          selectedOptions={selectedDurationOptions}
          setSelectedOptions={setSelectedDurationOptions}
          icon="icon-duration"
          placeholder="Duration"
          options={durations}
          dropdownName="Duration"
        />
        <DropdownMultivalue
          id="trip-filter-age"
          selectedOptions={selectedAgeOptions}
          setSelectedOptions={setSelectedAgeOptions}
          icon="icon-age"
          placeholder="Age"
          options={ages}
          dropdownName="Age"
        />
        {activityTagsData?.activity_tags_by_division_id.length ? (
          <DropdownMultivalue
            id="trip-filter-activities"
            selectedOptions={selectedActivityOptions}
            setSelectedOptions={setSelectedActivityOptions}
            icon="icon-donate"
            placeholder="Activities"
            options={activityTags}
            dropdownName="Activities"
          />
        ) : null}
        {allProviders.length && division && division.type === 'Tour' && (
          <DropdownMultivalue
            id="trip-filter-providers"
            selectedOptions={selectedProviderOptions}
            setSelectedOptions={setSelectedProviderOptions}
            icon={<TourIconSVG isMedium />}
            placeholder="Operator"
            options={allProviders}
            dropdownName="Operator"
          />
        )}
      </div>
    </div>
  );
}

export { TripFilters };
